<template>
  <div class="app_con">
    <div class="p-20">
      <el-main class="mainBox">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="账号" prop="loginName">
            <el-input
              v-model="ruleForm.loginName"
              placeholder="请输入账号"
              size=""
            ></el-input>
          </el-form-item>

          <el-form-item label="验证码" prop="valiCode">
            <el-row :span="24">
              <el-col :span="15">
                <el-input
                  v-model="ruleForm.valiCode"
                  auto-complete="off"
                  placeholder="请输入验证码"
                  size=""
                  maxlength="6"
                  @keyup.enter.native="submitForm('ruleForm')"
                ></el-input>
              </el-col>
              <el-col :span="7">
                <div class="login-code">
                  <!--验证码组件-->
                  <el-button
                    @click="getCode()"
                    :class="{ 'disabled-style': getCodeBtnDisable }"
                    :disabled="getCodeBtnDisable"
                    >{{ codeBtnWord }}
                  </el-button>
                </div>
              </el-col>
            </el-row>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >登录</el-button
            >
            <!-- <el-button @click="test">测试</el-button> -->
          </el-form-item>
        </el-form>
      </el-main>
    </div>
  </div>
</template>

<script>
import api from "@/api/common";

export default {
  data() {
    // let self = this;
    return {
      ruleForm: {
        loginName: "",
        valiCode: "",
      },
      codeBtnWord: "获取验证码", // 获取验证码按钮文字
      // waitTime: 61, // 获取验证码按钮失效时间
      waitTime: 2, // 获取验证码按钮失效时间
      // 校验
      rules: {
        code: [{ required: true, message: "请输入验证密码", trigger: "blur" }],
      },
    };
  },
  created() {},
  watch: {},
  computed: {
    // 控制获取验证码按钮是否可点击
    getCodeBtnDisable: {
      get() {
        if (this.waitTime === 2) {
          if (this.ruleForm.loginName !== "") {
            return false;
          }
          return true;
        }
        return true;
      },

      set() {
        this.waitTime = 60;
      },
    },
  },
  mounted() {},

  filters: {},

  methods: {
    async getCode() {
      let res = await api.commonGetDiy({
        url: "/login/sendmsg",
        baseURL: "/ctripApi",
        params: {
          loginName: this.ruleForm.loginName,
        },
      });

      if (res.code == 200) {
        // 因为下面用到了定时器，需要保存this指向
        let that = this;
        that.waitTime--;
        that.getCodeBtnDisable = true;
        this.codeBtnWord = `${this.waitTime}s 后重新获取`;
        let timer = setInterval(function () {
          if (that.waitTime > 1) {
            that.waitTime--;
            that.codeBtnWord = `${that.waitTime}s 后重新获取`;
          } else {
            clearInterval(timer);
            that.codeBtnWord = "获取验证码";
            that.getCodeBtnDisable = false;
            that.waitTime = 2;
          }
        }, 1000);
      } else {
        this.$message.error(res.message);
      }
    },

    submitForm(formName) {
      //判断输入的验证码是否为空
      if (this.ruleForm.valiCode != null) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            let res = await api.commonGet({
              apiName: "/ctrip/old/spring/login",
              loginName: this.ruleForm.loginName,
              valiCode: this.ruleForm.valiCode,
            });
            if (res.code == 200) {
              this.$message.success("登录成功！");
            }
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      } else {
        this.$message({
          showClose: true,
          message: "请输入错误",
          type: "error",
        });
      }
    },
  },
};
</script>

<style scoped>
.mainBox {
  width: 450px;
  border: 1px solid #ccc;
  margin: 0 auto  ;
}
</style>
